import React, { useState, useEffect } from "react";
import { Grid as AntGrid, ConfigProvider } from 'antd';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
  setTwoToneColor,
} from "@ant-design/icons";
import {
  Text, useTheme, View, Image, Heading,
  Flex,
  Grid,
 } from '@aws-amplify/ui-react';
import type { MenuProps } from "antd";
import {
  Breadcrumb,
  Layout,
  Menu,
  // theme,
  Button,
  Space,
  Checkbox,
  Form,
  Input,
} from "antd";
import { Auth } from "aws-amplify";
import { Authenticator, ThemeProvider as AmplifyThemeProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { components } from "./components/login/loginComp";
import { setupAmplify } from "./aws-exports";

import NavBar from "./components/navbar";
import SideBar from "./components/sidebar";
import TestApi from "./components/testapi";
import Home from "./components/home";
import HomeAdmin from "./components/home-admin";
import JYSubmit from "./components/jy-submit";
import JYHistory from "./components/jy-history";
import RDHistory from "./components/rd-history";
import UMapping from "./components/u-mapping";
import JYSubmitTest from "./components/test/jy-submit-test";
import JYSubmitTestNew from "./components/test/jy-submit-test-new";
import Notif from "./components/testNotif";
import ManualCallback from "./components/manual-callback";
import QueryRedemption from "./components/queryRedemption";
import SBSubmit from "./components/slowboat/submit";
import SBMapping from "./components/slowboat/mapping";
import SBInventory from "./components/slowboat/inventory";
import EMNetworkSelection from "./components/elitemobile/recharge/NetworkSelection";
import EMPhoneNumberInput from './components/elitemobile/recharge/PhoneNumberInput';
import EMTopupSelection from './components/elitemobile/recharge/TopUpSelection';
import EMConfirmation from './components/elitemobile/recharge/Confirmation';
import ShopeeViewOrders from "./components/shopee-view-orders";
import RechargeHistory from "./components/elitemobile/recharge/RechargeHistory";
import SBRedemptions from "./components/slowboat/redemption";
import theme from "./theme";
import amplifyTheme from "./themeAmplify";

//
// Menu Props for ant.d
//
const { useBreakpoint } = AntGrid;
const { Header, Content, Footer, Sider } = Layout;

//
// Amplify init
//
let credentials = null;
await setupAmplify().then((creds) => {
  console.log(`Amplify configured with ${JSON.stringify(creds)}`);
  credentials = creds;
});
Auth.configure(credentials);

//
// Theme configuration
//
if (theme?.token?.colorPrimary !== undefined) {
  setTwoToneColor(theme.token.colorPrimary)
}

//
// Fn Component
//
const App: React.FC = () => {

  const [session, setSession] = useState<any>(null);
  const screens = useBreakpoint();


  const { tokens } = useTheme();

  return (
    <>
      <ConfigProvider theme={theme}>
        <AmplifyThemeProvider theme={amplifyTheme}>
        <Authenticator
          loginMechanisms={["username"]}
          signUpAttributes={["email"]}
          components={components}
          hideSignUp
        >
        <Router>
        <Layout>
          <Header
            style={{
              position: "sticky",
              top: 0,
              zIndex: 100,
              width: "100%",
              display: "flex",
              alignItems: "center",
              textAlign:"right"
            }}
          >
            <NavBar />
          </Header>
          <Layout>
          <Sider
                  width={200}
                  breakpoint="md" // Responsive collapse behavior
                  collapsedWidth={screens.md ? "80" : "80"} // set collapsed width to 0 on small screens
                  collapsible
                  theme="light"
                >
                  <SideBar />
              </Sider>
            <Layout style={{ padding: "24px 24px 24px", minHeight: "95vh" }}>
              <Content
                style={{
                  padding: 24,
                  margin: 0,
                  minHeight: 280,
                }}
              >
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/home-admin" element={<HomeAdmin />} />
                  <Route path="/testapi" element={<TestApi />} />
                  <Route path="/jy-submit" element={<JYSubmit />} />
                  <Route path="/jy-submit-test" element={<JYSubmitTest />} />
                  <Route path="/jy-history" element={<JYHistory />} />
                  <Route path="/rd-history" element={<RDHistory />} />
                  <Route path="/u-mapping" element={<UMapping/>} />
                  <Route path="/test-notif" element={<Notif/>} />
                  <Route path="/jy-submit-test-new" element={<JYSubmitTestNew/>} />
                  <Route path="/sb-submit" element={<SBSubmit />} />
                  <Route path="/sb-mapping" element={<SBMapping />} />
                  <Route path="/sb-inventory" element={<SBInventory />} />
                  <Route path="/sb-redemptions" element={<SBRedemptions />} />
                  <Route path="/manual-cb" element={<ManualCallback/>} />
                  <Route path="/query-rdp" element={<QueryRedemption/>} />
                  <Route path="/em-recharge" element={<EMNetworkSelection />} />
                  <Route path="/phone-number" element={<EMPhoneNumberInput />} />
                  <Route path="/topup" element={<EMTopupSelection />} />
                  <Route path="/confirmation" element={<EMConfirmation/>} />
                  <Route path="/shopee-view-orders" element={<ShopeeViewOrders />} />
                  <Route path="/em-recharge-history" element={<RechargeHistory />} />
                </Routes>
              </Content>
              <Footer style={{ textAlign: "center" }}>
                Echo Your Travels Admin Portal
              </Footer>
            </Layout>
          </Layout>
        </Layout>
      </Router>
      </Authenticator>
      </AmplifyThemeProvider>
    </ConfigProvider>
    </>
  );
};

export default App;
